import { useOs } from '@wpp-open/react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'

import { AppRoutesProviders } from 'app/AppRoutesProviders'
import { routes } from 'app/routes'
import { ApiErrorTypes } from 'constants/apiErrors'
import { useFlags } from 'hooks/useFlag'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { ErrorPage } from 'ui-base/errorPage/ErrorPage'
import { RoutesManager } from 'utils/routesManager'

export const AppRoutes = () => {
  const { isPermitted } = useIsPermitted()
  const { flags } = useFlags()
  const {
    osContext: { baseUrl },
  } = useOs()

  const accessibleRoutesList = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppRoutesProviders />}>
        <Route key="applications" index element={<Navigate to={RoutesManager.applications.root.getURL()} replace />} />
        {routes.map(({ permissions, requireAllPermissions, flag, Component, path }, index) => {
          const isRoutePermitted = !permissions || isPermitted(permissions, requireAllPermissions)
          const availableFeature = !flag || flags[flag]

          return availableFeature ? (
            <Route
              key={path || index}
              errorElement={<ErrorPage errorType={ApiErrorTypes.OTHER_ERROR} />}
              path={path}
              element={isRoutePermitted ? Component : <ErrorPage errorType={ApiErrorTypes.ACCESS_DENIED} />}
            />
          ) : null
        })}
        <Route path="*" key="not-found" element={<ErrorPage errorType={ApiErrorTypes.NOT_FOUND} />} />
      </Route>,
    ),
    { basename: `/${baseUrl}` },
  )

  return <RouterProvider router={accessibleRoutesList} />
}
