import { HTMLAttributes, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ApplicationIndustryLabel } from 'types/applications/labels'
import { BlueprintDTO } from 'types/blueprint/blueprint'
import { Tooltip } from 'ui-base/tooltip/Tooltip'
import { Truncate } from 'ui-base/truncate/Truncate'
import { TruncatedTag } from 'ui-shared/truncatedTag/TruncatedTag'

import * as S from 'ui-shared/blueprintCard/BlueprintCard.styled'

interface Props extends HTMLAttributes<HTMLWppCardElement> {
  blueprint: BlueprintDTO
}

export const BlueprintCard = ({ blueprint, ...rest }: Props) => {
  const { t } = useTranslation(['applications', 'common'])

  const translatedIndustries = useMemo(
    () => blueprint.industries?.map(item => t(`applications|${ApplicationIndustryLabel[item]}`)),
    [blueprint, t],
  )

  return (
    <S.BlueprintCard data-testid="blueprint-card" interactive {...rest}>
      <div slot="header">
        <Tooltip data-testid="blueprint-header-tooltip" show showOnOverflow text={blueprint.name}>
          <Truncate data-testid="blueprint-header" type="l-strong">
            {blueprint.name}
          </Truncate>
        </Tooltip>
      </div>
      {blueprint.agency.name && (
        <S.AgencyName data-testid="blueprint-agency-name" type="xs-body">
          by {blueprint.agency.name}
        </S.AgencyName>
      )}
      {blueprint.shortDescription && (
        <Tooltip show showOnOverflow text={blueprint.shortDescription}>
          <S.Description data-testid="blueprint-description" type="s-body">
            {blueprint.shortDescription}
          </S.Description>
        </Tooltip>
      )}
      <S.Tags data-testid="blueprint-tags" gap={4}>
        <TruncatedTag data-testid="industry-tag" tags={translatedIndustries} applyMaxLabelLength={true} />
        <TruncatedTag data-testid="clients-tag" tags={blueprint.clients} applyMaxLabelLength={true} />
      </S.Tags>
    </S.BlueprintCard>
  )
}
