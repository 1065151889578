export enum FEATURE_TYPES {
  WPP_PRODUCT_ONBOARDING = 'WPP_PRODUCT_ONBOARDING',
  WPP_BLUEPRINTS_CATALOGUE = 'WPP_BLUEPRINTS_CATALOGUE',
  WPP_COLLECTIONS_CATALOGUE = 'WPP_COLLECTIONS_CATALOGUE',
  WPP_AVAILABILITY_FILTER = 'WPP_AVAILABILITY_FILTER',
  WPP_PARTNERS = 'WPP_PARTNERS',
  WPP_EMAIL = 'WPP_EMAIL',
}

export const featureFlags: Record<FEATURE_TYPES, string> = {
  WPP_PRODUCT_ONBOARDING: 'true',
  WPP_BLUEPRINTS_CATALOGUE: 'true',
  WPP_COLLECTIONS_CATALOGUE: 'WPP_TEMPLATE_COLLECTIONS_CATALOGUE_FEATURE',
  WPP_AVAILABILITY_FILTER: 'true',
  WPP_PARTNERS: 'true',
  WPP_EMAIL: 'WPP_TEMPLATE_EMAIL',
}

const featureFlagsConfig = Object.keys(FEATURE_TYPES).reduce((acc, type) => {
  const flags =
    process.env.NODE_ENV !== 'development' ? featureFlags[type as FEATURE_TYPES] : process.env[`REACT_APP_${type}`]
  return { ...acc, [type]: flags }
}, {} as Record<FEATURE_TYPES, string>)

export default featureFlagsConfig
