import styled from '@emotion/styled'
import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'ui-base/flex/Flex'
import { Truncate } from 'ui-base/truncate/Truncate'

export const CollectionLink = styled(Link)`
  text-decoration: none;
`

export const CollectionImageWrapper = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--wpp-primary-color-100);
`

export const CollectionInfo = styled(Flex)`
  // 40px (icon) + 16px (gap) + 20px (actions)
  max-width: calc(100% - 76px);
`

export const CollectionName = styled(Truncate)`
  overflow: hidden;

  &::part(typography) {
    display: block;
  }
`

export const ApplicationsCount = styled(WppTypography)`
  color: var(--wpp-grey-color-800);
`
