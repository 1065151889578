import { PropsWithChildren } from 'react'

import { useUserPermissionsApiFactory } from 'api/permissions/queries/useUserPermissionsApiFactory'
import { useAuth } from 'hooks/useAuth'
import { PermissionsContext } from 'providers/permissions/PermissionsContext'

export const PermissionsProvider = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, userDetails } = useAuth()
  const enabled = isAuthenticated && !!userDetails?.email
  const useUserPermissionsApi = useUserPermissionsApiFactory()
  const { data, isLoading } = useUserPermissionsApi({ staleTime: 60 * 1000, enabled })

  return (
    <PermissionsContext.Provider
      value={{
        permissions: data || [],
        isPermissionsLoading: isLoading,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  )
}
