import { yupResolver } from '@hookform/resolvers/yup'
import fastDeepEqual from 'fast-deep-equal'
import { useEffect, useState, useCallback, useRef } from 'react'
import { useForm as useReactHookForm, UseFormProps, Path, SetValueConfig, ValidationMode } from 'react-hook-form'
import { usePrevious } from 'react-use'

const defaultResetOptions = {
  keepErrors: false,
  keepDirty: false,
  keepIsSubmitted: false,
  keepTouched: false,
  keepIsValid: false,
  keepSubmitCount: false,
}

export const useForm = <FormValues extends Record<string, any>, FormContext extends Record<string, any> | void = void>({
  validationSchema,
  defaultValues,
  mode = 'onChange',
  reValidateMode = 'onChange',
  ...rest
}: Omit<UseFormProps<FormValues, FormContext>, 'mode' | 'reValidateMode' | 'resolver' | 'defaultValues'> &
  Required<Pick<UseFormProps<FormValues, FormContext>, 'defaultValues'>> & {
    defaultValues: FormValues
    validationSchema?: Parameters<typeof yupResolver>[0]
    mode?: keyof ValidationMode | undefined
    reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit' | undefined
  }) => {
  const form = useReactHookForm<FormValues, FormContext>({
    ...rest,
    defaultValues,
    mode: mode,
    reValidateMode: reValidateMode,
    ...(!!validationSchema && {
      resolver: yupResolver(validationSchema),
    }),
  })

  const { reset: originalReset, setError, setValue } = form

  /*
    Due to react-hook form architecture
    reset should be called after all running updates inside useForm hook

    Common case - you need reset form after submit. In this case you should call reset
    inside useEffect on next render.

    Here is the replacement that has same identity but works stable
    and there is no need to pollute code with useEffects in whole project.
  */
  type OriginalReset = typeof originalReset

  const [shouldReset, setShouldReset] = useState(false)
  const lastResetCallParamsRef = useRef<Parameters<OriginalReset>>([])

  const reset: OriginalReset = useCallback((...params) => {
    lastResetCallParamsRef.current = params
    setShouldReset(true)
  }, [])

  useEffect(() => {
    if (shouldReset) {
      const [values, keepStateOptions] = lastResetCallParamsRef.current

      originalReset(values, {
        ...defaultResetOptions,
        ...keepStateOptions,
      })

      setShouldReset(false)
    }
  }, [shouldReset, originalReset])

  // handle first render as usePrevious always returns undefined on first render
  const prevDefaultValues = usePrevious(defaultValues) || defaultValues

  /*
    react-hook-form doesn't support reinitialization due to its architecture.
    defaultValues are registered only on first render.

    Here is performant implementation for this functionality.
  */
  useEffect(() => {
    if (!fastDeepEqual(prevDefaultValues, defaultValues)) {
      originalReset(defaultValues, defaultResetOptions)
    }
  }, [prevDefaultValues, defaultValues, originalReset])

  // Utility to set batched form errors
  const setErrors = useCallback(
    (errors: Partial<Record<keyof FormValues, string>>) => {
      Object.entries(errors).forEach(([fieldName, errorText], index) => {
        setError(fieldName as Path<FormValues>, { message: errorText }, { shouldFocus: index === 0 })
      })
    },
    [setError],
  )

  // Utility to set batched form values
  const setValues = useCallback(
    (values: Partial<FormValues>, options?: SetValueConfig) => {
      Object.entries(values).forEach(([fieldName, fieldValue]) => {
        setValue(fieldName as Path<FormValues>, fieldValue, options)
      })
    },
    [setValue],
  )

  return { ...form, reset, setErrors, setValues }
}
