import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { FetcherResponse, FetcherParameters, QueryFetcher } from 'api/common/types'

export function createUseMutation<Fetcher extends QueryFetcher>({ fetcher }: { fetcher: Fetcher }) {
  return function useCustomMutation(
    options?: UseMutationOptions<FetcherResponse<Fetcher>, unknown, FetcherParameters<Fetcher>>,
  ) {
    return useMutation(({ signal, ...rest }) => fetcher(rest)(signal), options)
  }
}
