import styled from '@emotion/styled'

import { Flex } from 'ui-base/flex/Flex'

export const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
`

export const CollectionsHeader = styled(Flex)`
  margin-bottom: 32px;
`

export const NoCollectionsWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
`
