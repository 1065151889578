import { useQueryClient } from '@tanstack/react-query'

import { deleteCollection } from 'api/collections/fetchers/deleteCollection'
import { createUseMutation } from 'api/common/createUseMutation'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useDeleteCollectionApi = createUseMutation({ fetcher: deleteCollection })

export const useDeleteCollectionApiWithEffects = () => {
  const queryClient = useQueryClient()
  return useDeleteCollectionApi({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.COLLECTION])
    },
  })
}
