import { useCallback, useContext, useMemo } from 'react'

import { PermissionsContext } from 'providers/permissions/PermissionsContext'
import { AppPermissions } from 'types/permissions/enums'

export type PermissionsArg = AppPermissions | AppPermissions[]

const checkPermissions = (permissions: string[], requiredPermissions?: PermissionsArg, requireAll?: boolean) => {
  const permissionsToCheck = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions]

  if (!permissions.length) return false
  if (!permissionsToCheck.length) return true
  if (requireAll) return permissionsToCheck?.every(permission => permissions.includes(permission!))

  return permissionsToCheck?.some(permission => permissions.includes(permission!))
}

export const useIsPermitted = () => {
  const { permissions: allPermissions, isPermissionsLoading } = useContext(PermissionsContext)

  const isPermitted = useCallback(
    (requiredPermissions?: PermissionsArg, requireAll?: boolean) =>
      checkPermissions(
        allPermissions.flatMap(({ permissions }) => permissions),
        requiredPermissions,
        requireAll,
      ),
    [allPermissions],
  )

  const isPermittedByAgency = useCallback(
    (agencyExternalId?: string, requiredPermissions?: PermissionsArg, requireAll?: boolean) => {
      if (!agencyExternalId) return false

      const agencyPermissions = allPermissions
        // Note: accountId is not always agencyExternalId, WPP_OPEN_MARKET_AGENCIES_READ telling us that accountId === agencyExternalId
        .filter(node => node.permissions.includes(AppPermissions.WPP_OPEN_MARKET_AGENCIES_READ))
        .find(permission => permission.accountId === agencyExternalId)

      if (!agencyPermissions) return false

      return checkPermissions(agencyPermissions.permissions, requiredPermissions, requireAll)
    },
    [allPermissions],
  )

  const userAgencies = useMemo(
    () =>
      allPermissions
        .filter(node => node.permissions.includes(AppPermissions.WPP_OPEN_MARKET_AGENCIES_READ))
        .map(({ accountId }) => accountId),
    [allPermissions],
  )

  return {
    isPermitted,
    isPermittedByAgency,
    userAgencies,
    isLoading: isPermissionsLoading,
  }
}
