import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BaseOption, CheckboxItems, Props } from 'ui-base/checkboxGroup/CheckboxItems'

import * as S from 'ui-base/checkboxGroup/CheckboxGroup.styled'

interface CheckboxListProps<O, V> extends Props<O, V> {
  maxOptionsToShow?: number
  minOptionsToTruncate?: number
}

export const CheckboxGroup = <O extends BaseOption, V extends string | number>(props: CheckboxListProps<O, V>) => {
  const {
    name,
    value,
    onChange,
    options,
    getOptionLabel,
    getOptionValue,
    showTooltip,
    marginTop,
    labelWidth,
    maxOptionsToShow = 4,
    minOptionsToTruncate = 2,
    ...rest
  } = props

  const { t } = useTranslation()

  const [moreShown, setMoreShown] = useState(false)

  const { shownOptions, hiddenOptions } =
    options.length < maxOptionsToShow + minOptionsToTruncate
      ? { shownOptions: options, hiddenOptions: [] }
      : {
          shownOptions: options.slice(0, maxOptionsToShow + 1),
          hiddenOptions: options.slice(maxOptionsToShow - 1, options.length),
        }

  const shownProps = { ...props, options: shownOptions }
  const hiddenProps = { ...props, options: hiddenOptions }

  return (
    <ul {...rest}>
      {!!shownOptions.length && <CheckboxItems {...shownProps} />}
      {!!hiddenOptions.length && (
        <>
          {moreShown && <CheckboxItems {...hiddenProps} />}
          <S.ListItem>
            <S.ShowMore onClick={() => setMoreShown(!moreShown)}>
              <WppTypography type="s-strong" color="var(--wpp-primary-color-500)">
                {!moreShown && <>{`+ ${t('show_more')} (${hiddenOptions.length})`}</>}
                {moreShown && <>{`- ${t('show_less')}`}</>}
              </WppTypography>
            </S.ShowMore>
          </S.ListItem>
        </>
      )}
    </ul>
  )
}
