export type CollectionDTO = {
  id: number
  name: string
  description: string
  applicationsCount: number
  createdAt: string
  updatedAt: string
}

export type ApplicationCollections = {
  collections: CollectionDTO['id'][]
}

export enum CollectionControls {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export type ControlCollectionType = keyof typeof CollectionControls

export type CollectionFormDTO = {
  name: string
  description: string
}
