import { WppGrid } from '@platform-ui-kit/components-library-react'
import { Navigate, Route, Routes, useMatch } from 'react-router-dom'

import { FEATURE_TYPES } from 'featureFlagsConfig'
import { useFlag } from 'hooks/useFlag'
import { ApplicationsListPage } from 'pages/applications/list/ApplicationsListPage'
import { BlueprintsPage } from 'pages/blueprints/list/BlueprintsPage'
import { CatalogueFilters } from 'pages/catalogue/main/CatalogueFilters'
import { CollectionsListPage } from 'pages/collections/list/CollectionsListPage'

import * as S from 'pages/catalogue/main/CatalogueMainPage.styled'

export enum CatalogueRoutes {
  APPLICATIONS = 'applications/*',
  BLUEPRINTS = 'blueprints/*',
  COLLECTIONS = 'collections/*',
}

export const CatalogueMainPage = () => {
  const isApplicationsPage = useMatch(CatalogueRoutes.APPLICATIONS)
  const isBlueprintsPage = useMatch(CatalogueRoutes.BLUEPRINTS)

  const isBlueprintsAvailable = useFlag(FEATURE_TYPES.WPP_BLUEPRINTS_CATALOGUE)
  const isCollectionsAvailable = useFlag(FEATURE_TYPES.WPP_COLLECTIONS_CATALOGUE)

  const hasSidePanel = isApplicationsPage || isBlueprintsPage

  return (
    <S.CatalogueGrid container rowSpacing={4}>
      {hasSidePanel && (
        <WppGrid item all={6} data-testid="filters-block">
          <CatalogueFilters />
        </WppGrid>
      )}
      <WppGrid item all={hasSidePanel ? 18 : 24}>
        <Routes>
          <Route path={CatalogueRoutes.APPLICATIONS} element={<ApplicationsListPage />} />
          {isBlueprintsAvailable && <Route path={CatalogueRoutes.BLUEPRINTS} element={<BlueprintsPage />} />}
          {isCollectionsAvailable && <Route path={CatalogueRoutes.COLLECTIONS} element={<CollectionsListPage />} />}
          <Route path="*" element={<Navigate to={'applications'} replace />} />
        </Routes>
      </WppGrid>
    </S.CatalogueGrid>
  )
}
