import styled from '@emotion/styled'
import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'
import { Truncate } from 'ui-base/truncate/Truncate'

export const BlueprintCard = styled(WppCard)`
  display: flex; // card will stretch by height (fill full height)
  &::part(card) {
    display: flex;
    flex-direction: column;
    height: auto; // to let all inner parts of card be visible inside card
  }
  &::part(header-wrapper) {
    height: unset;
  }
`

export const AgencyName = styled(WppTypography)`
  &::part(typography) {
    color: var(--wpp-grey-color-800);
  }
`

export const Description = styled(Truncate)`
  margin: 8px 0 16px;
  &::part(typography) {
    color: var(--wpp-grey-color-800);
  }
`

export const Tags = styled(Flex)`
  margin-top: auto;
  overflow: hidden;
  max-width: 100%;
  flex-wrap: wrap; // to wrap tag on new line
`
