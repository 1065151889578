import styled from '@emotion/styled'
import { WppIconArrow } from '@platform-ui-kit/components-library-react'

import { FormTextArea } from 'ui-base/form/formTextArea/FormTextArea'

export const NameInput = styled(FormTextArea)`
  &::part(textarea) {
    height: 42px;
    word-break: break-word;
  }
`

export const DescriptionInput = styled(FormTextArea)`
  &::part(textarea) {
    height: 106px;
    word-break: break-word;
  }
`

export const BackwardsArrow = styled(WppIconArrow)`
  transform: rotate(180deg);
`
