import { HTMLAttributes, RefCallback, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteBlueprints } from 'api/applications/queries/useInfiniteBlueprints'
import { ApiErrorTypes } from 'constants/apiErrors'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { BlueprintsContent } from 'pages/blueprints/list/components/BlueprintsContent'
import { AppPermissions } from 'types/permissions/enums'
import { ErrorPage } from 'ui-base/errorPage/ErrorPage'
import { CatalogueIntroSection } from 'ui-shared/catalogueIntroSection/CatalogueIntroSection'
import { handleApiErrors } from 'utils/api'

import * as S from 'pages/blueprints/list/BlueprintPage.styled'

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const BlueprintsPage = ({ ...rest }: Props) => {
  const { t } = useTranslation(['errors', 'catalogue'])
  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])
  const { isPermitted } = useIsPermitted()
  const isBlueprintsAllowed = isPermitted(AppPermissions.WPP_OPEN_MARKET_ALL_BLUEPRINTS_READ)
  const {
    data: blueprintsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isError,
    error,
  } = useInfiniteBlueprints({
    enabled: isBlueprintsAllowed,
  })
  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  })

  if (!isBlueprintsAllowed) {
    return <ErrorPage errorType={ApiErrorTypes.ACCESS_DENIED} />
  }

  if (isError && !isLoading) {
    return handleApiErrors(error, { margin: '100px auto' })
  }

  return (
    <S.Wrapper data-testid="blueprints-list-wrapper" direction="column" gap={24} {...rest}>
      <CatalogueIntroSection
        data-testid="blueprints-catalogue-intro"
        title={t('catalogue|blueprints_intro_section_title')}
      />
      <BlueprintsContent
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        setRef={setRef}
        blueprintsData={blueprintsData}
      />
    </S.Wrapper>
  )
}
