import { WppButton, WppModal } from '@platform-ui-kit/components-library-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteCollectionApiWithEffects } from 'api/collections/mutations/useDeleteCollection'
import { useToast } from 'hooks/useToast'
import { CollectionDTO } from 'types/collections/collections'
import { MayBeNull } from 'types/common/utils'
import { Flex } from 'ui-base/flex/Flex'
import { unpackApiError } from 'utils/form'

interface Props {
  collection: MayBeNull<CollectionDTO>
  open: boolean
  handleClose: () => void
}

export const DeleteCollectionModal = ({ collection, open, handleClose }: Props) => {
  const { t } = useTranslation(['common', 'collections'])

  const { mutateAsync: deleteCollection, isLoading } = useDeleteCollectionApiWithEffects()
  const { showToast } = useToast()

  const handleDelete = useCallback(async () => {
    try {
      await deleteCollection({ id: collection?.id! })
      showToast({ message: t('collections|modals.delete_collection.success'), type: 'success' })
    } catch (apiError) {
      const { detail } = unpackApiError(apiError)
      const defaultError = t('collections|modals.delete_collection.error')
      showToast({ message: detail || defaultError, type: 'error' })
    } finally {
      handleClose()
    }
  }, [collection, showToast, deleteCollection, handleClose, t])

  return (
    <WppModal open={open} disableOutsideClick data-testid="delete-collection-modal">
      <h3 slot="header" data-testid="delete-collection-modal-title">
        {t('collections|modals.delete_collection.title')}
      </h3>
      <div slot="body">{t('collections|modals.delete_collection.body')}</div>
      <Flex justify="flex-end" align="center" gap={12} slot="actions">
        <WppButton
          data-testid="delete-collection-modal-cancel-button"
          variant="secondary"
          size="s"
          onClick={handleClose}
        >
          {t('common|cancel')}
        </WppButton>
        <WppButton
          data-testid="delete-collection-modal-delete-button"
          variant="destructive"
          loading={isLoading}
          size="s"
          onClick={handleDelete}
        >
          {t('common|delete')}
        </WppButton>
      </Flex>
    </WppModal>
  )
}
