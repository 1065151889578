import styled from '@emotion/styled'

import { Flex } from 'ui-base/flex/Flex'
import { BlueprintCard } from 'ui-shared/blueprintCard/BlueprintCard'

export const Card = styled(BlueprintCard)`
  width: calc(50% - 6px);
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`

export const Spinner = styled(Flex)`
  margin: 100px 0 0;
`

export const Wrapper = styled(Flex)`
  max-width: 1132px;
  width: 100%;
`
