import { ApiResponseError } from 'types/api/api'

export const unpackApiError = (apiError: any) => {
  const { violations, status, detail, code } = (apiError as ApiResponseError)?.response?.data || {}

  return { violations, status, detail, code }
}

export const getFormErrors = (apiError: any) => {
  const { violations } = unpackApiError(apiError)

  if (violations) {
    return violations.reduce<Record<string, string>>((acc, { field, message }) => ({ ...acc, [field]: message }), {})
  }
}
