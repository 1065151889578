import { WppButton, WppGrid, WppIconPlus, WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAllCollections } from 'api/collections/queries/useAllCollections'
import { ApiErrorTypes } from 'constants/apiErrors'
import { useCustomDialog } from 'hooks/useCustomDialog'
import { useInternalApiError } from 'hooks/useInternalApiError'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { CollectionCard } from 'pages/collections/list/components/collectionCard/CollectionCard'
import { DeleteCollectionModal } from 'pages/collections/list/components/deleteCollcetionModal/DeleteCollectionModal'
import { CollectionDTO, CollectionControls, ControlCollectionType } from 'types/collections/collections'
import { MayBeNull } from 'types/common/utils'
import { AppPermissions } from 'types/permissions/enums'
import { ErrorPage } from 'ui-base/errorPage/ErrorPage'
import { CustomWppSpinner } from 'ui-base/spinner/Spinner'
import { AddCollectionImage } from 'ui-base/svgIcons/addCollectionImage/AddCollectionImage'
import { CollectionFormSideModal } from 'ui-shared/collectionFormSideModal/CollectionFormSideModal'

import * as S from 'pages/collections/list/CollectionsListPage.styled'

export const CollectionsListPage = () => {
  const { t } = useTranslation(['common', 'collections'])

  const { isPermitted } = useIsPermitted()
  const isCollectionsListPageAllowed = isPermitted(AppPermissions.WPP_OPEN_MARKET_ALL_REVIEWED_PUBLIC_PRODUCTS_READ)

  const { addError } = useInternalApiError()

  const { data: collections, isError, isLoading } = useAllCollections({ onError: (error: any) => addError(error) })

  const { open, handleOpen, handleClose } = useCustomDialog()
  const [controlCollection, setControlCollection] = useState<MayBeNull<CollectionDTO>>(null)
  const [controlType, setControlType] = useState<MayBeNull<ControlCollectionType>>(null)
  const handleCollectionControl = useCallback(
    (type: ControlCollectionType, collection: MayBeNull<CollectionDTO> = null) => {
      setControlCollection(collection)
      setControlType(type)
      handleOpen()
    },
    [handleOpen],
  )

  if (!isCollectionsListPageAllowed) {
    return <ErrorPage errorType={ApiErrorTypes.ACCESS_DENIED} />
  }

  if (isError && !isLoading) {
    return <ErrorPage errorType={ApiErrorTypes.SERVER_ERROR} style={{ margin: '100px 0' }} />
  }

  if (isLoading) {
    return <CustomWppSpinner size="l" data-testid="collections-loading-spinner" />
  }

  return (
    <S.Wrapper direction="column" data-testid="collections-list-wrapper">
      <S.CollectionsHeader justify="between">
        <WppTypography type="3xl-heading">{t('common|collections')}</WppTypography>
        {!!collections && collections.length > 0 && (
          <WppButton
            size="s"
            variant="primary"
            data-testid="new-collection-header-button"
            onClick={() => handleCollectionControl(CollectionControls.CREATE)}
          >
            <WppIconPlus slot="icon-start" />
            {t('collections|new_collection')}
          </WppButton>
        )}
      </S.CollectionsHeader>

      {!!collections && collections.length === 0 && (
        <S.NoCollectionsWrapper direction="column" align="center" justify="center" gap={16}>
          <AddCollectionImage />
          <WppTypography type="l-strong">{t('collections|empty_collections')}</WppTypography>
          <WppButton
            size="s"
            variant="primary"
            data-testid="new-collection-button"
            onClick={() => handleCollectionControl(CollectionControls.CREATE)}
          >
            <WppIconPlus slot="icon-start" />
            {t('collections|new_collection')}
          </WppButton>
        </S.NoCollectionsWrapper>
      )}

      {!!collections && collections.length > 0 && (
        <WppGrid container fullWidth>
          {collections.map(collection => (
            <WppGrid key={collection.id} item all={8}>
              <CollectionCard collection={collection} onCollectionControl={handleCollectionControl} />
            </WppGrid>
          ))}
        </WppGrid>
      )}

      {controlType === CollectionControls.CREATE && <CollectionFormSideModal isOpen={open} handleClose={handleClose} />}

      {controlType === CollectionControls.UPDATE && (
        <CollectionFormSideModal
          collection={controlCollection}
          isOpen={open}
          handleClose={handleClose}
          onDelete={() => handleCollectionControl(CollectionControls.DELETE, controlCollection)}
        />
      )}

      {controlType === CollectionControls.DELETE && (
        <DeleteCollectionModal collection={controlCollection} open={open} handleClose={handleClose} />
      )}
    </S.Wrapper>
  )
}
