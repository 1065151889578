import { TFunction } from 'i18next'
import * as Yup from 'yup'

const maxNameLength = 80
const maxDescriptionLength = 250

export const getCollectionScheme = (t: TFunction<['collections']>) =>
  Yup.object({
    name: Yup.string().max(
      maxNameLength,
      t('collections|modals.collection_form.validation.name_max_length', {
        charsCount: maxNameLength,
      }),
    ),
    description: Yup.string().max(
      maxDescriptionLength,
      t('collections|modals.collection_form.validation.description_max_length', {
        charsCount: maxDescriptionLength,
      }),
    ),
  })
