import { PropsWithChildren, useMemo } from 'react'

import featureFlagsConfig, { FEATURE_TYPES } from 'featureFlagsConfig'
import { FlagsContext } from 'providers/flags/FlagsContext'

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const flags = useMemo(
    () => ({
      [FEATURE_TYPES.WPP_EMAIL]: false,
      [FEATURE_TYPES.WPP_BLUEPRINTS_CATALOGUE]: featureFlagsConfig.WPP_BLUEPRINTS_CATALOGUE === 'true',
      [FEATURE_TYPES.WPP_COLLECTIONS_CATALOGUE]: featureFlagsConfig.WPP_COLLECTIONS_CATALOGUE === 'true',
      [FEATURE_TYPES.WPP_AVAILABILITY_FILTER]: featureFlagsConfig.WPP_AVAILABILITY_FILTER === 'true',
      [FEATURE_TYPES.WPP_PRODUCT_ONBOARDING]: featureFlagsConfig.WPP_PRODUCT_ONBOARDING === 'true',
      [FEATURE_TYPES.WPP_PARTNERS]: featureFlagsConfig.WPP_PARTNERS === 'true',
    }),
    [],
  )

  return (
    <FlagsContext.Provider
      value={{
        flags,
      }}
    >
      {children}
    </FlagsContext.Provider>
  )
}
