import { WppActionButton, WppButton, WppIconTrash, WppSideModal } from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateCollectionApiWithEffects } from 'api/collections/mutations/useCreateCollection'
import { useUpdateCollectionApiWithEffects } from 'api/collections/mutations/useUpdateCollection'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { CollectionDTO, CollectionFormDTO } from 'types/collections/collections'
import { MayBeNull } from 'types/common/utils'
import { Flex } from 'ui-base/flex/Flex'
import { getCollectionScheme } from 'ui-shared/collectionFormSideModal/utils'
import { getFormErrors, unpackApiError } from 'utils/form'

import * as S from 'ui-shared/collectionFormSideModal/CollectionFormSideModal.styled'

interface Props {
  isOpen: boolean
  handleClose: () => void
  collection?: MayBeNull<CollectionDTO>
  onBackNavigation?: () => void
  onDelete?: () => void
}

export const CollectionFormSideModal = ({ isOpen, collection, handleClose, onBackNavigation, onDelete }: Props) => {
  const { t } = useTranslation(['collections', 'common'])
  const isUpdateMode = !!collection?.id
  const { mutateAsync: updateCollection, isLoading: isUpdateLoading } = useUpdateCollectionApiWithEffects()
  const { mutateAsync: createCollection, isLoading: isCreateLoading } = useCreateCollectionApiWithEffects()
  const isLoading = isUpdateLoading || isCreateLoading
  const { showToast } = useToast()

  const form = useForm<CollectionFormDTO>({
    defaultValues: {
      name: collection?.name || '',
      description: collection?.description || '',
    },
    validationSchema: getCollectionScheme(t),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const { handleSubmit, reset, setErrors } = form

  useEffect(() => {
    if (isOpen) reset()
  }, [reset, isOpen])

  const handleSave = async (values: Partial<CollectionDTO>) => {
    try {
      if (isUpdateMode) {
        await updateCollection({ name: values.name!, description: values.description!, id: collection?.id! })
      } else {
        await createCollection({ name: values.name!, description: values.description! })
      }
      showToast({
        message: t(`collections|modals.collection_form.success_${isUpdateMode ? 'updated' : 'created'}`),
        type: 'success',
      })
      handleClose()
    } catch (apiError) {
      const errors = getFormErrors(apiError)
      const { detail } = unpackApiError(apiError)
      if (errors) {
        setErrors(errors)
      } else {
        const defaultError = t(`collections|modals.collection_form.error_${isUpdateMode ? 'update' : 'create'}`)
        showToast({
          message: detail || defaultError,
          type: 'error',
        })
      }
    }
  }

  return (
    <WppSideModal
      size="m"
      disableOutsideClick
      open={isOpen}
      onWppSideModalClose={handleClose}
      data-testid="collection-form-modal"
    >
      <h3 slot="header" data-testid="collection-form-modal-title">
        {t('collections|modals.collection_form.title')}
      </h3>
      <FormProvider {...form}>
        <Flex direction="column" gap={16} slot="body">
          <S.NameInput
            name="name"
            labelConfig={{ text: t('common|name') }}
            charactersLimit={80}
            warningThreshold={80}
            locales={{ charactersEntered: t('common|characters') }}
            data-testid="collection-form-name-input"
            required
          />

          <S.DescriptionInput
            name="description"
            labelConfig={{ text: t('common|description') }}
            charactersLimit={250}
            warningThreshold={250}
            locales={{ charactersEntered: t('common|characters') }}
            data-testid="collection-form-description-input"
          />
        </Flex>
      </FormProvider>
      <Flex justify="between" slot="actions">
        <Flex>
          {!!onDelete && (
            <WppActionButton onClick={onDelete} variant="destructive" data-testid="collection-form-delete-button">
              <WppIconTrash slot="icon-start" />
              {t('common|delete')}
            </WppActionButton>
          )}
          {!!onBackNavigation && (
            <WppActionButton onClick={onBackNavigation} data-testid="collection-form-back-button">
              <S.BackwardsArrow slot="icon-start" color="var(--wpp-primary-color-500)" />
              {t('common|back')}
            </WppActionButton>
          )}
        </Flex>
        <Flex justify="flex-end" gap={12}>
          <WppButton onClick={handleClose} variant="secondary" data-testid="collection-form-cancel-button">
            {t('common|cancel')}
          </WppButton>
          <WppButton
            loading={isLoading}
            size="s"
            data-testid="collection-form-save-button"
            onClick={() => handleSubmit(handleSave)()}
          >
            {t('common|save')}
          </WppButton>
        </Flex>
      </Flex>
    </WppSideModal>
  )
}
