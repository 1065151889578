import { createContext } from 'react'

export interface PermissionsRecord {
  accountId: string
  permissions: string[]
}

export type PermissionsContextValue = {
  permissions: PermissionsRecord[]
  isPermissionsLoading?: boolean
}

export const PermissionsContext = createContext<PermissionsContextValue>(null!)
