import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const AddCollectionImage = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="161" height="154" viewBox="0 0 161 154" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4590_104458)">
      <g filter="url(#filter0_f_4590_104458)">
        <ellipse cx="78.2241" cy="121.221" rx="66.0865" ry="11.8899" className="fillPrimary300" />
      </g>
      <path
        d="M81 146.812C119.108 146.812 150 115.962 150 77.9062C150 39.8504 119.108 9 81 9C42.8924 9 12 39.8504 12 77.9062C12 115.962 42.8924 146.812 81 146.812Z"
        className="fillPrimary200"
      />
      <path
        d="M74.8623 60.5742L75.482 60.557H100.684C106.507 60.557 111.266 65.1149 111.587 70.8579L111.604 71.4776V96.6792C111.604 102.503 107.046 107.261 101.303 107.583L100.684 107.6H75.482C69.6587 107.6 64.8999 103.042 64.5786 97.2989L64.5614 96.6792V71.4776C64.5614 65.6543 69.1193 60.8955 74.8623 60.5742ZM100.684 65.5973H75.482C72.3968 65.5973 69.8665 67.9733 69.6212 70.9954L69.6017 71.4776V96.6792C69.6017 99.7645 71.9777 102.295 74.9998 102.54L75.482 102.56H100.684C103.769 102.56 106.299 100.184 106.544 97.1615L106.564 96.6792V71.4776C106.564 68.23 103.931 65.5973 100.684 65.5973ZM88.0828 70.6418C89.4747 70.6418 90.603 71.7701 90.603 73.162V81.5558L99.0035 81.5583C100.395 81.5583 101.524 82.6866 101.524 84.0784C101.524 85.4703 100.395 86.5986 99.0035 86.5986L90.603 86.5961V95.0033C90.603 96.3952 89.4747 97.5235 88.0828 97.5235C86.691 97.5235 85.5627 96.3952 85.5627 95.0033V86.5961L77.1621 86.5986C75.7703 86.5986 74.642 85.4703 74.642 84.0784C74.642 82.6866 75.7703 81.5583 77.1621 81.5583L85.5627 81.5558V73.162C85.5627 71.7701 86.691 70.6418 88.0828 70.6418ZM90.0378 47.9027L90.2149 48.4968L92.5418 57.1942H87.3234L85.3463 49.8013C84.5058 46.6644 81.2814 44.8027 78.1444 45.6433L53.8015 52.1659C50.8297 52.9623 49.0024 55.8981 49.533 58.8722L49.6435 59.3679L56.1661 83.7108C56.8118 86.1203 58.864 87.7775 61.2026 88.0354L61.203 93.0935C56.8306 92.8363 52.9001 89.9446 51.4746 85.6094L51.2976 85.0153L44.7749 60.6724C43.2677 55.0475 46.4387 49.2712 51.9029 47.4745L52.497 47.2974L76.8399 40.7747C82.4648 39.2675 88.2411 42.4385 90.0378 47.9027Z"
        className="fillPrimary500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.18 34.2562C127.341 34.2562 128.283 33.316 128.283 32.1562C128.283 30.9964 127.341 30.0562 126.18 30.0562C125.019 30.0562 124.077 30.9964 124.077 32.1562C124.077 33.316 125.019 34.2562 126.18 34.2562ZM126.18 35.8312C128.212 35.8312 129.86 34.1858 129.86 32.1562C129.86 30.1266 128.212 28.4812 126.18 28.4812C124.148 28.4812 122.5 30.1266 122.5 32.1562C122.5 34.1858 124.148 35.8312 126.18 35.8312Z"
        className="fillPrimary300"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.78 142.669C108.941 142.669 109.883 141.729 109.883 140.569C109.883 139.409 108.941 138.469 107.78 138.469C106.619 138.469 105.677 139.409 105.677 140.569C105.677 141.729 106.619 142.669 107.78 142.669ZM107.78 144.244C109.812 144.244 111.46 142.598 111.46 140.569C111.46 138.539 109.812 136.894 107.78 136.894C105.748 136.894 104.1 138.539 104.1 140.569C104.1 142.598 105.748 144.244 107.78 144.244Z"
        className="fillPrimary300"
      />
      <path
        d="M6.85462 119.466C8.47391 119.202 9.78307 120.839 9.19801 122.395L8.40508 124.505C7.82608 126.045 5.82202 126.352 4.78771 125.059L3.38606 123.307C2.35175 122.014 3.05756 120.083 4.66004 119.823L6.85462 119.466Z"
        className="fillPrimary300"
      />
      <path
        d="M142.801 88.9653C142.424 87.411 143.852 86.023 145.403 86.4364L148.526 87.2689C150.113 87.6919 150.636 89.6743 149.462 90.8156L147.098 93.1127C145.924 94.254 143.947 93.6851 143.561 92.0949L142.801 88.9653Z"
        className="fillPrimary300"
      />
      <path
        d="M76.5059 5.95418C76.9954 4.14027 79.6847 4.14027 80.1742 5.95418L80.4308 6.90483C80.6085 7.56328 81.1556 8.07127 81.8483 8.22084L82.3823 8.33614C84.3527 8.76161 84.3527 11.4509 82.3823 11.8764L81.8483 11.9917C81.1556 12.1412 80.6084 12.6492 80.4308 13.3077L80.1742 14.2583C79.6847 16.0722 76.9954 16.0722 76.5059 14.2583L76.2494 13.3077C76.0717 12.6492 75.5245 12.1412 74.8319 11.9917L74.2979 11.8764C72.3275 11.4509 72.3275 8.76161 74.2979 8.33614L74.8319 8.22084C75.5245 8.07127 76.0717 7.56328 76.2494 6.90483L76.5059 5.95418Z"
        className="fillPrimary400"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_4590_104458"
        x="-9.63542"
        y="87.5583"
        width="175.719"
        height="67.3258"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_4590_104458" />
      </filter>
      <clipPath id="clip0_4590_104458">
        <rect width="160" height="154" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </S.CustomSvg>
)
