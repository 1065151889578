import { Global as GlobalStyles, css } from '@emotion/react'
import debounce from 'lodash.debounce'
import { useContext, Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { Content } from 'layout/content/Content'
import { Header } from 'layout/header/Header'
import { InternalApiErrorContext } from 'providers/internalApiError/InternalApiErrorContext'
import { MarketplaceSizeProvider } from 'providers/marketplaceSize/MarketplaceSizeProvider'
import { PermissionsContext } from 'providers/permissions/PermissionsContext'
import { CustomWppSpinner } from 'ui-base/spinner/Spinner'
import { handleApiErrors } from 'utils/api'

export const Layout = () => {
  const { isAuthenticated } = useAuth()
  const { isPermissionsLoading } = useContext(PermissionsContext)
  const { error } = useContext(InternalApiErrorContext)

  const isReady = isAuthenticated && !isPermissionsLoading
  const hideHeader = !!error && error?.response?.status >= 500
  const pageChildren = !!error ? handleApiErrors(error) : <Outlet />

  const [scrollWidth, setState] = useState<number>(0)
  const calculateScroll = debounce(() => {
    const innerWidth = window.innerWidth
    if (!isReady || (innerWidth > 1280 && innerWidth < 1300)) {
      return
    }
    const newScrollWidth = innerWidth - document.documentElement.clientWidth
    if (scrollWidth !== newScrollWidth) {
      setState(newScrollWidth)
    }
  }, 100)
  useEffect(() => {
    window.addEventListener('resize', calculateScroll)
    return () => {
      window.removeEventListener('resize', calculateScroll)
    }
  })
  useEffect(() => {
    const postponedCalculation = setTimeout(() => calculateScroll, 1000)
    return () => {
      clearTimeout(postponedCalculation)
    }
  })

  return (
    <MarketplaceSizeProvider>
      {!hideHeader && <Header />}
      <Content>
        <Suspense fallback={<CustomWppSpinner delay={200} size="l" />}>
          {isReady ? pageChildren : <CustomWppSpinner size="l" />}
        </Suspense>
      </Content>
      <GlobalStyles // TODO update it from the OS theme
        styles={css`
          body {
            min-width: ${1280 - scrollWidth}px;
            background: var(--wpp-grey-color-100);
            --wpp-marketplace-header-height: ${hideHeader ? 0 : 49}px;
            --mc-item-width: 100%;
          }

          #root,
          body,
          html {
            height: auto;
          }
        `}
      />
    </MarketplaceSizeProvider>
  )
}
