import { marketPlaceApi } from 'api'
import { CollectionDTO } from 'types/collections/collections'

interface Params {
  id: CollectionDTO['id']
  name: CollectionDTO['name']
  description?: CollectionDTO['description']
}

export const updateCollection = ({ id, name, description }: Params) =>
  marketPlaceApi.put(`/users/current/preferences/collections/${id}`, description ? { name, description } : { name })
