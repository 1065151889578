import { marketPlaceApi } from 'api'
import { CollectionDTO } from 'types/collections/collections'

interface Params {
  name: CollectionDTO['name']
  description?: CollectionDTO['description']
}

export const createCollection = ({ name, description }: Params) =>
  marketPlaceApi.post('/users/current/preferences/collections', description ? { name, description } : { name })
