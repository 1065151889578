import {
  WppActionButton,
  WppCard,
  WppIconAppFolder,
  WppIconEdit,
  WppIconMore,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
} from '@platform-ui-kit/components-library-react'
import { MouseEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { CollectionDTO, CollectionControls, ControlCollectionType } from 'types/collections/collections'
import { Flex } from 'ui-base/flex/Flex'
import { Tooltip } from 'ui-base/tooltip/Tooltip'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'pages/collections/list/components/collectionCard/CollectionCard.styled'

type Props = {
  collection: CollectionDTO
  onCollectionControl: (type: ControlCollectionType, collection: CollectionDTO) => void
}

export const CollectionCard = ({ collection, onCollectionControl }: Props) => {
  const { t } = useTranslation(['common', 'collections'])
  const { id, name, applicationsCount } = collection

  const actionsContextRef = useRef<HTMLWppMenuContextElement>(null)
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const isButtonClicked = actionsContextRef.current?.contains(event.target as Node)
    if (isButtonClicked) {
      event.preventDefault()
    }
  }

  return (
    <S.CollectionLink to={RoutesManager.collections.view.getURL({ id })} onClick={handleClick}>
      <WppCard size="l">
        <div slot="actions">
          <WppMenuContext ref={actionsContextRef} listWidth="200px">
            <WppActionButton variant="secondary" slot="trigger-element">
              <WppIconMore direction="horizontal" color="var(--wpp-grey-color-800)" />
            </WppActionButton>
            <WppListItem onWppChangeListItem={() => onCollectionControl(CollectionControls.UPDATE, collection)}>
              <WppIconEdit slot="left" color="var(--wpp-grey-color-600)" />
              <p slot="label">{t('common|edit')}</p>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => onCollectionControl(CollectionControls.DELETE, collection)}>
              <WppIconTrash slot="left" color="var(--wpp-grey-color-600)" />
              <p slot="label">{t('common|delete')}</p>
            </WppListItem>
          </WppMenuContext>
        </div>
        <Flex align="center" direction="row" gap={16}>
          <S.CollectionImageWrapper align="center" justify="center">
            <WppIconAppFolder color="var(--wpp-primary-color-500)" />
          </S.CollectionImageWrapper>
          <S.CollectionInfo direction="column">
            <Tooltip
              show
              showOnOverflow
              getInnerElement={el => el?.shadowRoot?.querySelector('[part="typography"]')}
              text={name}
              data-testid="collection-name-tooltip"
            >
              <S.CollectionName type="l-strong" tag="p" data-testid="collection-name">
                {name}
              </S.CollectionName>
            </Tooltip>
            <S.ApplicationsCount type="xs-midi">
              {t('collections|application_count', { count: applicationsCount })}
            </S.ApplicationsCount>
          </S.CollectionInfo>
        </Flex>
      </WppCard>
    </S.CollectionLink>
  )
}
