export enum AppPermissions {
  WPP_OPEN_MARKET_ALL_REVIEWED_PUBLIC_PRODUCTS_READ = 'WPP_OPEN_MARKET_ALL_REVIEWED_PUBLIC_PRODUCTS_READ',

  WPP_OPEN_MARKET_ALL_BLUEPRINTS_READ = 'WPP_OPEN_MARKET_ALL_BLUEPRINTS_READ',

  WPP_OPEN_MARKET_ALL_AGENCIES_READ = 'WPP_OPEN_MARKET_ALL_AGENCIES_READ',
  WPP_OPEN_MARKET_AGENCIES_READ = 'WPP_OPEN_MARKET_AGENCIES_READ', // check if node account_id === externalId

  WPP_OPEN_MARKET_SELF_PRODUCTS_READ = 'WPP_OPEN_MARKET_SELF_PRODUCTS_READ',
  WPP_OPEN_MARKET_AGENCY_PRODUCTS_READ = 'WPP_OPEN_MARKET_AGENCY_PRODUCTS_READ',
  WPP_OPEN_MARKET_ALL_PRODUCTS_READ = 'WPP_OPEN_MARKET_ALL_PRODUCTS_READ',

  WPP_OPEN_MARKET_ALL_PRODUCTS_REVIEW = 'WPP_OPEN_MARKET_ALL_PRODUCTS_REVIEW',
  WPP_OPEN_MARKET_AGENCY_PRODUCTS_REVIEW = 'WPP_OPEN_MARKET_AGENCY_PRODUCTS_REVIEW',

  WPP_OPEN_MARKET_ALL_USER_REVIEW = 'WPP_OPEN_MARKET_ALL_USER_REVIEW',

  WPP_OPEN_MARKET_ALL_INTERNAL_CONTENT_READ = 'WPP_OPEN_MARKET_ALL_INTERNAL_CONTENT_READ',
}

type EnsureCorrectEnum<T extends { [K in Exclude<keyof T, number>]: K }> = true
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type EnsureCorrectPermissions = EnsureCorrectEnum<typeof AppPermissions>
