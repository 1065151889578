import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FEATURE_TYPES } from 'featureFlagsConfig'
import { useFlag } from 'hooks/useFlag'
import { ApplicationAvailabilityFilter } from 'pages/applications/list/components/ApplicationAvailabilityFilter'
import { ApplicationCheckboxFilter } from 'pages/applications/list/components/ApplicationCheckboxFilter'
import { ApplicationCommercialModel } from 'pages/applications/list/components/ApplicationCommercialModel'
import { ApplicationFiltersMarkets } from 'pages/applications/list/components/ApplicationFiltersMarkets'
import { ApplicationSelectableCardsFilter } from 'pages/applications/list/components/ApplicationSelectableCardsFilter'
import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { ApplicationCategory } from 'types/applications/enums'
import { applicationCategoryOptions } from 'types/applications/options'

import * as S from 'pages/applications/list/components/applicationsFilters/ApplicationsFilters.styled'

export const ApplicationsFilters = () => {
  const { applicationTenants } = useCatalogueFilters()
  const { t } = useTranslation(['applications'])

  const isAvailabilityFilterAvailable = useFlag(FEATURE_TYPES.WPP_AVAILABILITY_FILTER)

  const tenantList = useMemo(
    () =>
      Object.values(applicationTenants)
        .map(option => ({
          label: option.name,
          value: option.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [applicationTenants],
  )

  const { appFilters, setAppFilters } = useCatalogueFilters()
  const { categories, tenantIds } = appFilters

  return (
    <S.FiltersWrapper>
      <ApplicationSelectableCardsFilter
        data-testid="application-filters-categories"
        filterTitle={t('applications|filters.categories')}
        options={applicationCategoryOptions.map(item => ({
          ...item,
          label: t(`applications|application_categories.${item.label}.title`),
        }))}
        value={categories}
        onChange={value => setAppFilters({ categories: value as ApplicationCategory[] })}
      />
      <ApplicationCommercialModel />

      <ApplicationFiltersMarkets />

      {isAvailabilityFilterAvailable && <ApplicationAvailabilityFilter />}

      <ApplicationCheckboxFilter
        data-testid="application-filter-developed-by"
        hasSearch
        filterTitle={t('applications|filters.developed_by')}
        options={tenantList}
        value={tenantIds}
        onChange={value => setAppFilters({ tenantIds: value })}
      />
    </S.FiltersWrapper>
  )
}
