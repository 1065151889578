import { NavigationState } from '@platform-ui-kit/components-library'
import { WppGrid } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { FEATURE_TYPES } from 'featureFlagsConfig'
import { useAuth } from 'hooks/useAuth'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { FlagsContext } from 'providers/flags/FlagsContext'
import { Leaves } from 'types/common/utils'
import { CommonDictionaryTypes } from 'types/i18next'
import { AppPermissions } from 'types/permissions/enums'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'layout/header/Header.styled'

interface HeaderLinkConfig extends NavigationState {
  permissions?: AppPermissions | AppPermissions[]
  requireAllPermissions?: boolean
  label: Leaves<CommonDictionaryTypes>
  featureFlag?: FEATURE_TYPES
}

const headerLinks: HeaderLinkConfig[] = [
  {
    label: 'application_other',
    value: '/applications',
    path: RoutesManager.applications.root.getURL(),
    permissions: [AppPermissions.WPP_OPEN_MARKET_ALL_REVIEWED_PUBLIC_PRODUCTS_READ],
  },
  {
    label: 'blueprints',
    value: '/blueprints',
    path: RoutesManager.blueprints.root.getURL(),
    permissions: [AppPermissions.WPP_OPEN_MARKET_ALL_BLUEPRINTS_READ],
    featureFlag: FEATURE_TYPES.WPP_BLUEPRINTS_CATALOGUE,
  },
  {
    label: 'collections',
    value: '/collections',
    path: RoutesManager.collections.root.getURL(),
    permissions: [AppPermissions.WPP_OPEN_MARKET_ALL_REVIEWED_PUBLIC_PRODUCTS_READ],
    featureFlag: FEATURE_TYPES.WPP_COLLECTIONS_CATALOGUE,
  },
]

export const Header = () => {
  const { isAuthenticated } = useAuth()
  const { isPermitted, isLoading: isPermissionsLoading } = useIsPermitted()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { flags: featureFlags } = useContext(FlagsContext)
  const {
    osContext: { baseUrl },
  } = useOs()

  const navLinks: NavigationState[] = useMemo(
    () =>
      headerLinks
        .filter(({ permissions, requireAllPermissions, featureFlag }) => {
          const permitted = !permissions || isPermitted(permissions, requireAllPermissions)
          const flag = !featureFlag || featureFlags[featureFlag]
          return permitted && flag
        })
        .map(({ label, value, path, ...rest }) => {
          const adjustedVal = path?.substring(0, path.indexOf('/', 1)) || value // adjust values to be mapped with pathname
          return {
            ...rest,
            value: adjustedVal,
            label: t(label),
            path: `/${baseUrl}${path}`,
          }
        }),
    [baseUrl, featureFlags, isPermitted, t],
  )

  const handleNavigate = useCallback(
    (event: CustomEvent) => {
      const { path } = event.detail
      const relativePath = path.replace(`/${baseUrl}`, '')
      navigate(relativePath)
    },
    [navigate, baseUrl],
  )

  const activeRootPath = pathname.match(/^\/([^/]+)/)?.[0]
  const isLoading = !isAuthenticated || isPermissionsLoading

  return (
    <S.TopBarWrapper>
      <S.Wrapper>
        <WppGrid container>
          <S.Column item all={24}>
            {!isLoading && (
              <S.Toolbar
                navigation={navLinks}
                value={activeRootPath}
                onWppChange={handleNavigate}
                data-testid="header-topbar"
              >
                <div slot="app">
                  <S.Title type="m-strong" data-testid="header-title">
                    {t('marketplace')}
                  </S.Title>
                </div>
              </S.Toolbar>
            )}
          </S.Column>
        </WppGrid>
      </S.Wrapper>
    </S.TopBarWrapper>
  )
}
