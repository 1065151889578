import { marketPlaceApi } from 'api'
import { PageSizes } from 'constants/pageSizes'
import { ApiRequestPaginationParameters, Paginated } from 'types/api/api'
import { BlueprintDTO } from 'types/blueprint/blueprint'

type Params = ApiRequestPaginationParameters & {
  pageParam?: number
}

export const fetchInfiniteBlueprints = ({ page = 0, size = PageSizes.PAGINATED_BLUEPRINTS_SIZE }: Params) =>
  marketPlaceApi.get<Paginated<BlueprintDTO>>('/blueprints', {
    params: { page, size },
  })
