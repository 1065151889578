import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { compress, decompress } from 'lz-string'
import { PropsWithChildren, useState } from 'react'

import { apiInstances } from 'api'
import { useAuth } from 'hooks/useAuth'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 20,
    },
  },
})

export const ApiProvider = ({ children }: PropsWithChildren) => {
  const { getAccessToken } = useAuth()

  useState(() =>
    apiInstances.forEach(instance => {
      instance.client.interceptors.request.use(
        config => {
          const token = getAccessToken()

          if (token) {
            config!.headers!.Authorization = `Bearer ${token}`
          }

          return config
        },
        error => Promise.reject(error),
      )

      instance.client.interceptors.response.use(
        response => response,
        error => Promise.reject(error),
      )
    }),
  )

  if (process.env.NODE_ENV === 'development') {
    persistQueryClient({
      queryClient,
      persister: createSyncStoragePersister({
        storage: window.sessionStorage,
        key: 'REACT_QUERY_WPP_OPEN_MP_OFFLINE_CACHE',
        serialize: data => compress(JSON.stringify(data)),
        deserialize: data => JSON.parse(decompress(data)!),
      }),
    })
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  )
}
