import { ReactElement, lazy } from 'react'

import { FEATURE_TYPES } from 'featureFlagsConfig'
import { CatalogueMainPage } from 'pages/catalogue/main/CatalogueMainPage'
import { AppPermissions } from 'types/permissions/enums'
import { RoutesManager } from 'utils/routesManager'

interface AppRoute {
  Component: ReactElement
  path: string
  permissions?: AppPermissions | AppPermissions[]
  requireAllPermissions?: boolean
  flag?: FEATURE_TYPES
}

const ApplicationPage = lazy(() =>
  import('pages/applications/view/ApplicationViewPage').then(({ ApplicationViewPage }) => ({
    default: ApplicationViewPage,
  })),
)

const CollectionPage = lazy(() =>
  import('pages/collections/view/CollectionViewPage').then(({ CollectionViewPage }) => ({
    default: CollectionViewPage,
  })),
)

export const routes: AppRoute[] = [
  {
    Component: <CatalogueMainPage />,
    path: RoutesManager.main.root.pattern,
  },
  {
    Component: <ApplicationPage />,
    path: RoutesManager.applications.view.pattern,
    permissions: AppPermissions.WPP_OPEN_MARKET_ALL_REVIEWED_PUBLIC_PRODUCTS_READ,
  },
  {
    Component: <CollectionPage />,
    path: RoutesManager.collections.view.pattern,
    permissions: AppPermissions.WPP_OPEN_MARKET_ALL_REVIEWED_PUBLIC_PRODUCTS_READ,
  },
]
