import { ApiErrorTypes } from 'constants/apiErrors'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { ApplicationsContent } from 'pages/applications/list/components/ApplicationsContent'
import { ApplicationsSearch } from 'pages/applications/list/components/ApplicationsSearch'
import { ApplicationFiltersTags } from 'pages/applications/list/components/filterTags/ApplicationFiltersTags'
import { useApplicationsQuery } from 'pages/applications/list/useApplicationsQuery'
import { AppPermissions } from 'types/permissions/enums'
import { ErrorPage } from 'ui-base/errorPage/ErrorPage'
import { CatalogueIntroSection } from 'ui-shared/catalogueIntroSection/CatalogueIntroSection'
import { CatalogueSearch } from 'ui-shared/catalogueIntroSection/components/CatalogueSearch'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

export const ApplicationsListPage = () => {
  const { isPermitted } = useIsPermitted()
  const isApplicationsListPageAllowed = isPermitted(AppPermissions.WPP_OPEN_MARKET_ALL_REVIEWED_PUBLIC_PRODUCTS_READ)

  const { isLoading, appsQueriesMap, isError, resultsNumber, hasFilters, isSearchApplied } = useApplicationsQuery()

  // page is not allowed
  if (!isApplicationsListPageAllowed) {
    return <ErrorPage errorType={ApiErrorTypes.ACCESS_DENIED} />
  }

  // error page
  if (isError && !isLoading) {
    return <ErrorPage errorType={ApiErrorTypes.SERVER_ERROR} style={{ margin: '100px 0' }} />
  }

  return (
    <S.Wrapper data-testid="application-list-wrapper" direction="column">
      {hasFilters ? (
        <>
          <CatalogueSearch>
            <ApplicationsSearch />
          </CatalogueSearch>
          <ApplicationFiltersTags
            isLoading={isLoading}
            resultsNumber={resultsNumber}
            isSearchApplied={isSearchApplied}
          />
        </>
      ) : (
        <CatalogueIntroSection>
          <ApplicationsSearch />
        </CatalogueIntroSection>
      )}
      <ApplicationsContent
        isLoading={isLoading}
        appsQueriesMap={appsQueriesMap}
        resultsNumber={resultsNumber}
        hasFilters={hasFilters}
      />
    </S.Wrapper>
  )
}
