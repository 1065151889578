import { WppTag } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes, useMemo } from 'react'

import { Tooltip } from 'ui-base/tooltip/Tooltip'
import { truncateTags } from 'utils/tags'

interface Props extends HTMLAttributes<HTMLWppTooltipElement> {
  tags: string[]
  limitTags?: number
  applyMaxLabelLength?: boolean
}

export const TruncatedTag = ({ tags, limitTags, applyMaxLabelLength, ...rest }: Props) => {
  const { label, fullLabel, isTruncated } = useMemo(() => truncateTags(tags, limitTags), [tags, limitTags])
  const additionalTagAttributes = applyMaxLabelLength ? { maxLabelLength: label.length } : {}
  if (tags.length === 0) {
    return null
  }
  return (
    <Tooltip show={isTruncated} text={fullLabel} {...rest}>
      <WppTag
        data-testid="tag"
        tooltipConfig={{ onShow: () => false }}
        variant="neutral"
        label={label}
        {...additionalTagAttributes}
      />
    </Tooltip>
  )
}
