import { useOs } from '@wpp-open/react'

import { createUseQuery } from 'api/common/createUseQuery'
import { fetchUserPermissionsApi } from 'api/permissions/fetchers/fetchUserPermissionsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useUserPermissionsApiFactory = () => {
  const {
    osContext: {
      tenant: { azId: currentUserTenantId },
    },
  } = useOs()
  const fetcher = () => fetchUserPermissionsApi({ currentUserTenantId })

  return createUseQuery({
    queryKey: ApiQueryKeys.USER_PERMISSIONS,
    fetcher: fetcher,
    selector: res => res?.data || [],
  })
}
