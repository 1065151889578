import { useQueryClient } from '@tanstack/react-query'

import { createCollection } from 'api/collections/fetchers/createCollection'
import { createUseMutation } from 'api/common/createUseMutation'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useCreateCollectionApi = createUseMutation({ fetcher: createCollection })

export const useCreateCollectionApiWithEffects = () => {
  const queryClient = useQueryClient()
  return useCreateCollectionApi({
    onSuccess: async response => {
      queryClient.setQueryData([ApiQueryKeys.COLLECTION, { id: response.data.id }], response)
      await queryClient.invalidateQueries([ApiQueryKeys.COLLECTIONS])
    },
  })
}
