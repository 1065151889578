import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes, RefCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { BlueprintDTO } from 'types/blueprint/blueprint'
import { EmptyState } from 'ui-base/emptyState/EmptyState'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/blueprints/list/BlueprintPage.styled'

interface Props extends HTMLAttributes<HTMLDivElement> {
  blueprintsData: BlueprintDTO[]
  isLoading: boolean
  isFetchingNextPage: boolean
  setRef: RefCallback<HTMLDivElement>
}

export const BlueprintsContent = ({ blueprintsData, setRef, isFetchingNextPage, isLoading }: Props) => {
  const { t } = useTranslation(['errors', 'catalogue'])
  const isInitialLoading = isLoading && !isFetchingNextPage
  if (!isLoading && !blueprintsData.length) {
    return (
      <EmptyState
        data-testid="blueprint-empty-state"
        message={{ title: t('errors|no_blueprints.title'), subtitle: t('errors|no_blueprints.subtitle') }}
        showImage
        margin="100px 0"
      />
    )
  }

  if (isInitialLoading) {
    return (
      <S.Spinner justify="center">
        <WppSpinner size="l" />
      </S.Spinner>
    )
  }

  return (
    <>
      <Flex data-testid="blueprint-cards-wrapper" wrap="wrap" gap={12}>
        {blueprintsData.map(item => (
          <S.Card key={item.id} blueprint={item} />
        ))}
      </Flex>

      <Flex data-testid="blueprint-load-more" justify="center" ref={setRef}>
        {isFetchingNextPage && <WppSpinner size="m" />}
      </Flex>
    </>
  )
}
