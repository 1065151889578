import { useQueryClient } from '@tanstack/react-query'

import { updateCollection } from 'api/collections/fetchers/updateCollection'
import { createUseMutation } from 'api/common/createUseMutation'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useUpdateCollectionApi = createUseMutation({ fetcher: updateCollection })

export const useUpdateCollectionApiWithEffects = () => {
  const queryClient = useQueryClient()
  return useUpdateCollectionApi({
    onSuccess: async response => {
      queryClient.setQueryData([ApiQueryKeys.COLLECTION, { id: response.data.id }], response)
      await queryClient.invalidateQueries([ApiQueryKeys.COLLECTIONS])
    },
  })
}
